<template>
  <div class="recovery">
    <div v-if="loading" class="loading"></div>
    <div v-else-if="!recovered">
      <div v-if="valid">
        <h2>パスワードの再設定</h2>
        <p>
        新しいパスワード(10文字以上)を入力してください。
        </p>
        <label>
      パスワード<br/>
        <input type="password" v-model="password" @input="input" />
        </label><br/>
        <label>
      パスワード再入力<br/>
        <input type="password" v-model="password2" @input="input" />
        </label><br/>
        <button @click="recover" :disabled="disableNext">再設定</button>
      </div>
      <div v-else>
        <p>認証できませんでした。</p>
      </div>
    </div>
    <div v-else>
      パスワードを再設定しました。
      <router-link to="/login">こちらからログインしてください。</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config'

export default {
	name: 'Recovery',
	data() {
		return {
      password: '',
      password2: '',
      disableNext: true,
      valid: false,
      recovered: false,
      loading: true
		}
	},
  mounted() {
    axios.get(config.API+'recovery-verify.php', {
      params: {
        id: this.$route.query.id,
        v: this.$route.query.v
      }
    }).then(res => {
      this.valid = (res.data.status == 'success');
      this.loading = false;
    });
  },
	methods: {
    input() {
      this.disableNext = this.password.length < 10 || this.password2.length < 10 || this.password != this.password2;
    },
    recover() {
      this.loading = true;
      axios.post(config.API+'recovery-verify.php', {
        id: this.$route.query.id,
        v: this.$route.query.v,
        name: this.name,
        password: this.password,
        password2: this.password2,
      }).then(res => {
        if (res.data.status == 'success') {
          this.recovered = true;
        }
        else {
          alert(res.data.message);
        }
        this.loading = false;
      });
    }
	}
}
</script>

<style scoped>
.recovery {
	margin: 4pt;
  text-align: center;
}
.recovery input,
.recovery button {
	margin: 4pt 0;
}
</style>
